import { gsap } from "gsap";
import { TweenMax } from "gsap/all";
import React, { useState } from "react";
import * as THREE from 'three';
import { FlakesTexture } from 'three/examples/jsm/textures/FlakesTexture';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';

function Index(props) {
  const [scrollEnable, setScrollEnable] = useState(true);

  let group, camera, scene, renderer, mesh, meshGeometry, meshGeometry2;
  let count, position_clone, normals_clone, count2, position_clone2, normals_clone2;
  const damping = 0.5;
  const damping2 = 0.1;

  init();
  animate();

  React.useEffect(() => {
    document.getElementById('text1').style.opacity = 1;
    document.getElementById('text2').style.opacity = 0;
  }, []);

  function init() {

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);


    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.useLegacyLights = false;
    document.body.appendChild(renderer.domElement);

    // camera
    camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.set(-10, 0, 50);
    scene.add(camera);

    // ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // ambientLight.intensity
    scene.add(ambientLight);


    // point light
    const pointlight = new THREE.PointLight(0xffffff, 3, 0, 0);
    pointlight.position.set(100, 100, 100);
    scene.add(pointlight);

    // point light
    const pointlight2 = new THREE.PointLight(0xffffff, 3, 0, 0);
    pointlight2.position.set(-100, 200, 100);
    scene.add(pointlight2);

    // point light
    const pointlight3 = new THREE.PointLight(0xffffff, 3, 0, 0);
    pointlight3.position.set(0, -300, 100);
    scene.add(pointlight3);

    // textures
    const loader = new THREE.TextureLoader();
    const texture = loader.load('textures/sprites/disc.png');
    texture.colorSpace = THREE.SRGBColorSpace;

    group = new THREE.Group();
    scene.add(group);

    // points
    let dodecahedronGeometry = new THREE.DodecahedronGeometry(10);

    // if normal and uv attributes are not removed, mergeVertices() can't consolidate indentical vertices with different normal/uv data
    dodecahedronGeometry.deleteAttribute('normal');
    dodecahedronGeometry.deleteAttribute('uv');

    dodecahedronGeometry = BufferGeometryUtils.mergeVertices(dodecahedronGeometry);

    const vertices = [];
    const positionAttribute = dodecahedronGeometry.getAttribute('position');

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(positionAttribute, i);
      vertices.push(vertex);
    }

    const pointsMaterial = new THREE.PointsMaterial({
      color: 0x0080ff,
      map: texture,
      size: 1,
      alphaTest: 0.5
    });

    const pointsGeometry = new THREE.BufferGeometry().setFromPoints(vertices);

    const points = new THREE.Points(pointsGeometry, pointsMaterial);
    group.add(points);

    let texture2 = new THREE.CanvasTexture(new FlakesTexture());
    texture2.wrapS = THREE.RepeatWrapping;
    texture2.wrapT = THREE.RepeatWrapping;
    //repeat the wrapping 10 (x) and 6 (y) times
    texture2.repeat.x = 10;
    texture2.repeat.y = 6;

    // convex hull
    const meshMaterial = new THREE.MeshPhysicalMaterial({
      // color: 0x02bae3,
      clearcoat: 1.0,
      cleacoatRoughness: 1,
      metalness: 0,
      roughness: 1,
      color: 0x02bae3,
      normalMap: texture2,
      normalScale: new THREE.Vector2(0.15, 0.15),
      // envMap: texture2
    });

    const meshMaterial2 = new THREE.MeshPhysicalMaterial({
      // color: 0x02bae3,
      clearcoat: 1.0,
      cleacoatRoughness: 1,
      metalness: 0,
      roughness: 1,
      color: 0x0c62f7,
      normalMap: texture2,
      normalScale: new THREE.Vector2(0.15, 0.15),
      // envMap: texture2
    });

    

    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.25;

    var xDistance = 50;
    var zDistance = -50;
    var xOffset = 20;

    meshGeometry = new THREE.SphereGeometry(10, 128, 128);
    for (var j = 0; j < 3; j++) {
      mesh = new THREE.Mesh(meshGeometry, meshMaterial2);
      if (j === 2) {
        mesh.position.x = -100;
        mesh.position.y = (xDistance * j);
        mesh.position.z = (zDistance * j);
      }
      else {
        mesh.position.x = (xDistance * j) + (xOffset * j);
        mesh.position.y = (xDistance * j);
        mesh.position.z = (zDistance * j);
      }
      group.add(mesh);
    }

    //section 1 miniCircle 

    var xDistance2 = -30;
    var zDistance2 = -50;
    var xOffset2 = 50;

    meshGeometry2 = new THREE.SphereGeometry(3, 128, 128);
    const mesh1 = new THREE.Mesh(meshGeometry2, meshMaterial);
    mesh1.position.x = -30;
    mesh1.position.y = 10;
    mesh1.position.z = 10;
    group.add(mesh1);

    meshGeometry2 = new THREE.SphereGeometry(1, 128, 128);
    const mesh2 = new THREE.Mesh(meshGeometry2, meshMaterial);
    mesh2.position.x = -20;
    mesh2.position.y = 6;
    mesh2.position.z = 10;
    group.add(mesh2);

    meshGeometry2 = new THREE.SphereGeometry(2, 128, 128);
    const mesh3 = new THREE.Mesh(meshGeometry2, meshMaterial);
    mesh3.position.x = -18;
    mesh3.position.y = 7.5;
    mesh3.position.z = 10;
    group.add(mesh3);

    meshGeometry2 = new THREE.SphereGeometry(1, 128, 128);
    const mesh4 = new THREE.Mesh(meshGeometry2, meshMaterial);
    mesh4.position.x = 12;
    mesh4.position.y = 7;
    mesh4.position.z = 10;
    group.add(mesh4);
    
    meshGeometry2 = new THREE.SphereGeometry(1, 128, 128);
    const mesh5 = new THREE.Mesh(meshGeometry2, meshMaterial);
    mesh5.position.x = -10;
    mesh5.position.y = -10;
    mesh5.position.z = 10;
    group.add(mesh5);

    meshGeometry2 = new THREE.SphereGeometry(4, 128, 128);
    const mesh6 = new THREE.Mesh(meshGeometry2, meshMaterial2);
    mesh6.position.x = -35;
    mesh6.position.y = -6;
    mesh6.position.z = 10;
    group.add(mesh6);

    count = meshGeometry.attributes.position.count;
    position_clone = JSON.parse(JSON.stringify(meshGeometry.attributes.position.array));
    normals_clone = JSON.parse(JSON.stringify(meshGeometry.attributes.normal.array));

    count2 = meshGeometry2.attributes.position.count;
    position_clone2 = JSON.parse(JSON.stringify(meshGeometry2.attributes.position.array));
    normals_clone2 = JSON.parse(JSON.stringify(meshGeometry2.attributes.normal.array));

    //function
    window.addEventListener('resize', onWindowResize, false);
    window.addEventListener('wheel', onMouseWheel, false);
  }

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  var total = 0;
  var firstRotate = false;
  var secondRotate = false;
  // var firstRotate = false;

  function onMouseWheel(event) {
    if (scrollEnable) {
      total += event.deltaY;

      camera.position.x += event.deltaY / 50;
      camera.position.y += event.deltaY / 50;
      camera.position.z -= event.deltaY / 200;

      if (total >= 700 && total <= 2000 && firstRotate) {
        document.getElementById('text1').style.opacity = 0;
        document.getElementById('text2').style.opacity = 1;
        secondRotate = true;
        firstRotate = false;

        gsap.to(camera.position, {
          duration: 2,
          x: -100,
          y: 100,
          z: 25,
          onComplete: function () {
            firstRotate = true;
          }
        })
      }
      else if (total >= 300 && total <= 600 && !secondRotate) {
        document.getElementById('text1').style.opacity = 0;
        document.getElementById('text2').style.opacity = 1;
        secondRotate = true;
        firstRotate = true;

        gsap.to(camera.position, {
          duration: 1,
          x: 40,
          y: 50,
          z: 10,
          onComplete: function () {
          }
        })
      }
      else if (total <= 299 && firstRotate) {
        document.getElementById('text1').style.opacity = 1;
        document.getElementById('text2').style.opacity = 0;
        secondRotate = false;
        firstRotate = false;

        gsap.to(camera.position, {
          duration: 1,
          x: -10,
          y: 0,
          z: 50,
          onComplete: function () {
            console.log("DONE")
          }
        })
      }

      // camera.position.x = 50;
      // camera.position.y = 50;

      // mesh += event.deltaY / 100;

      // prevent scrolling beyond a min/max value
      // camera.position.clampScalar(0, 10);
    }
    else {
      return;
    }
  }

  function animate() {
    const now = Date.now() / 200;

    // iterate all vertices
    for (let i = 0; i < count; i++) {
      // indices
      const ix = i * 3
      const iy = i * 3 + 1
      const iz = i * 3 + 2

      // use uvs to calculate wave
      const uX = meshGeometry.attributes.uv.getX(i) * Math.PI * 2
      const uY = meshGeometry.attributes.uv.getY(i) * Math.PI * 2

      // calculate current vertex wave height
      const xangle = (uX + now)
      const xsin = Math.sin(xangle) * damping
      const yangle = (uY + now)
      const ycos = Math.cos(yangle) * damping

      // set new position
      meshGeometry.attributes.position.setX(i, position_clone[ix] + normals_clone[ix] * (xsin + ycos))
      meshGeometry.attributes.position.setY(i, position_clone[iy] + normals_clone[iy] * (0.5))
      meshGeometry.attributes.position.setZ(i, position_clone[iz] + normals_clone[iz] * (xsin + ycos))
    }

    for (let i = 0; i < count2; i++) {
      // indices
      const ix = i * 3
      const iy = i * 3 + 1
      const iz = i * 3 + 2

      // use uvs to calculate wave
      const uX = meshGeometry2.attributes.uv.getX(i) * Math.PI * 3
      const uY = meshGeometry2.attributes.uv.getY(i) * Math.PI * 3

      // calculate current vertex wave height
      const xangle = (uX + now)
      const xsin = Math.sin(xangle) * damping2
      const yangle = (uY + now)
      const ycos = Math.cos(yangle) * damping2

      // set new position
      meshGeometry2.attributes.position.setX(i, position_clone2[ix] + normals_clone2[ix] * (xsin + ycos))
      meshGeometry2.attributes.position.setY(i, position_clone2[iy] + normals_clone2[iy] * (0.5))
      meshGeometry2.attributes.position.setZ(i, position_clone2[iz] + normals_clone2[iz] * (xsin + ycos))
    }
    meshGeometry.computeVertexNormals();
    meshGeometry.attributes.position.needsUpdate = true;
    meshGeometry2.computeVertexNormals();
    meshGeometry2.attributes.position.needsUpdate = true;

    requestAnimationFrame(animate);
    // mesh.rotation.x += 0.01;
    // mesh.rotation.y += 0.02;
    renderer.render(scene, camera);
  }

  return (
    <>
      {/* <canvas id="canvas"></canvas> */}
      <div className="" style={{ background: "transparent", width: '100%', minHeight: '100vh', position: 'absolute' }}>
        <span className="h1" id="text1" style={{ color: "#fff", position: 'absolute', left: '0', right: '0', margin: 'auto', top: '50%', textAlign: 'center' }}>Welcome To Dothouz</span>
        <span className="h1" id="text2" style={{ color: "#fff", position: 'absolute', left: '25%', top: '50%', textAlign: 'center' }}>I love HWC Coffee</span>
      </div>
    </>
  );
}

export default Index;
